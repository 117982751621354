import React, {useEffect, useContext} from 'react';
import PropTypes from 'prop-types';
import {useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {useSelector, useDispatch} from 'react-redux';

import Form, {RadioGroup, RadioOption} from '../../../components/Form';
import Button from '../../../components/Button';
import {userTypeValidator} from '../../../../utils/form-validation';
import {Column, Container, Row} from '../../../layout/Grid';
import profileLang from '../../../../lang/pages/profil.lang';
import {sectionThemeNames} from '../../../layout/Grid/Section.styles';
import Headline from '../../../components/Headline';
import {
    setTempUserSettings,
    updateUserSettings,
    selectUserType,
} from '../../../../model/profile';
import LocationContext from '../../../utils/LocationContext';
import {matchLocation} from '../../../../utils/route-utils';
import {routes} from '../../../../model/navigation/routes';
import {ReactComponent as PharmacistSvg} from '../../../../assets/images/personalisierung/user-group/img-personalisierung--apotheker-default.svg';
import {ReactComponent as ApprenticeSvg} from '../../../../assets/images/personalisierung/user-group/img-personalisierung--azubi-default.svg';
import {ReactComponent as CommercialAssistantSvg} from '../../../../assets/images/personalisierung/user-group/img-personalisierung--pka-default.svg';
import {ReactComponent as TechnicalAssistantSvg} from '../../../../assets/images/personalisierung/user-group/img-personalisierung--pta-default.svg';
import Paragraph from '../../../components/Paragraph';

const validationSchema = yup.object().shape({
    userType: userTypeValidator,
});

export const UserTypeForm = ({onSubmit, theme, isStandalone, userType}) => {
    const defaultValues = {
        userType: userType,
    };

    const {control, handleSubmit, errors, reset} = useForm({
        resolver: yupResolver(validationSchema),
        defaultValues,
    });

    useEffect(() => {
        reset(defaultValues);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userType]);

    return (
        <Form
            theme={theme}
            onSubmit={handleSubmit(({userType}) => {
                onSubmit(userType);
            })}>
            <Container width="medium">
                <Row>
                    <Column xs={12}>
                        <Headline type="h6" align="center">
                            {profileLang.userTypeForm.headline}
                        </Headline>
                    </Column>
                    <Column xs={12}>
                        <RadioGroup
                            imgRadio
                            name="userType"
                            control={control}
                            errors={errors}>
                            <RadioOption
                                value="Apotheker/in"
                                img={<PharmacistSvg />}>
                                Apotheker/in
                            </RadioOption>
                            <RadioOption
                                value="Auszubildender"
                                img={<ApprenticeSvg />}>
                                Auszubildende/r
                            </RadioOption>
                            <RadioOption
                                value="PKA"
                                img={<CommercialAssistantSvg />}>
                                PKA
                            </RadioOption>
                            <RadioOption
                                value="PTA"
                                img={<TechnicalAssistantSvg />}>
                                PTA
                            </RadioOption>
                        </RadioGroup>
                        {!isStandalone && (
                            <Paragraph margin="1rem 0" align="center">
                                {profileLang.userTypeForm.preferencesHint}
                            </Paragraph>
                        )}
                    </Column>
                    <Column xs={12} className="ap-padding--top">
                        <Button
                            type="submit"
                            label={
                                isStandalone
                                    ? profileLang.submitButton
                                    : profileLang.userTypeForm
                                          .multiStepSubmitButton
                            }
                            className="ap-button"
                        />
                    </Column>
                </Row>
            </Container>
        </Form>
    );
};

UserTypeForm.propTypes = {
    onSubmit: PropTypes.func,
    theme: PropTypes.oneOf(sectionThemeNames),
    isStandalone: PropTypes.bool,
    userType: PropTypes.string,
};

UserTypeForm.defaultProps = {
    onSubmit: () => {},
    theme: 'default',
    isStandalone: true,
    userType: '',
};

const ConnectedUserTypeForm = (props) => {
    // NOTE: Default to unconnected component inside storybook environment
    // so that components that use the default export do not break.
    if (process.env.STORYBOOK_ENV === 'true') {
        return <UserTypeForm {...props} />;
    }
    const location = useContext(LocationContext); // eslint-disable-line
    const dispatch = useDispatch(); // eslint-disable-line
    const userType = useSelector(selectUserType); // eslint-disable-line
    const handleSubmit = (type) => {
        // NOTE: start page is the only place we are not logged in and want
        // to set the user type temporarily before registration, everywhere
        // else we are logged in and want to update it for real:
        if (matchLocation(routes.start, location)) {
            dispatch(setTempUserSettings(undefined, type));
        } else {
            dispatch(updateUserSettings(undefined, type, !props.isStandalone));
        }
    };
    return (
        <UserTypeForm {...props} onSubmit={handleSubmit} userType={userType} />
    );
};

ConnectedUserTypeForm.propTypes = UserTypeForm.propTypes;
ConnectedUserTypeForm.defaultProps = UserTypeForm.defaultProps;

export default ConnectedUserTypeForm;
