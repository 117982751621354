import React, {useEffect, useContext} from 'react';
import PropTypes from 'prop-types';
import {useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {useSelector, useDispatch} from 'react-redux';

import Form, {CheckboxGroup, CheckboxOption} from '../../../components/Form';
import Button from '../../../components/Button';
import {categoriesValidator} from '../../../../utils/form-validation';
import {Column, Container, Row} from '../../../layout/Grid';
import profileLang from '../../../../lang/pages/profil.lang';
import Headline from '../../../components/Headline';
import {selectCategories} from '../../../../model/training';
import {
    selectUserCategories,
    setTempUserSettings,
    updateUserSettings,
} from '../../../../model/profile';
import LocationContext from '../../../utils/LocationContext';
import {matchLocation} from '../../../../utils/route-utils';
import {routes} from '../../../../model/navigation/routes';
import Paragraph from '../../../components/Paragraph';

const validationSchema = yup.object().shape({
    categories: categoriesValidator,
});

export const PreferredCategoriesForm = ({
    onSubmit,
    categories,
    userCategories,
    isStandalone,
}) => {
    const defaultValues = {
        categories: categories.reduce(
            (categories, category) => ({
                ...categories,
                [category.id]: userCategories.includes(category.id),
            }),
            {
                // id: bool tupel
            }
        ),
    };

    const {control, handleSubmit, errors, reset} = useForm({
        resolver: yupResolver(validationSchema),
        defaultValues,
    });

    useEffect(() => {
        reset(defaultValues);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userCategories]);

    return (
        <Form
            onSubmit={handleSubmit((data) => {
                onSubmit(
                    Object.entries(data.categories)
                        .filter(([_id, selected]) => selected)
                        .map(([id, _selected]) => +id) // convert back to integer
                );
            })}>
            <Container width="medium">
                <Row>
                    <Column xs={12}>
                        <Headline type="h6" align="center">
                            {profileLang.preferredCategoriesForm.question}
                        </Headline>
                    </Column>
                    <Column xs={12}>
                        <CheckboxGroup
                            imgCheckbox
                            name="categories"
                            control={control}
                            errors={errors}>
                            {categories.map((category) => (
                                <CheckboxOption
                                    key={category.id}
                                    name={`${category.id}`} // convert integer to string
                                    label={category.name}
                                    imgDefault={category.image}
                                    imgChecked={category.imageSelected}
                                />
                            ))}
                        </CheckboxGroup>
                        {!isStandalone && (
                            <Paragraph margin="1rem 0" align="center">
                                {
                                    profileLang.preferredCategoriesForm
                                        .preferencesHint
                                }
                            </Paragraph>
                        )}
                    </Column>
                    <Column xs={12} className="ap-padding--top">
                        <Button
                            type="submit"
                            label={
                                isStandalone
                                    ? profileLang.submitButton
                                    : profileLang.preferredCategoriesForm
                                          .multiStepSubmitButton
                            }
                            className="ap-button"
                        />
                    </Column>
                </Row>
            </Container>
        </Form>
    );
};

PreferredCategoriesForm.propTypes = {
    onSubmit: PropTypes.func,
    categories: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number,
            name: PropTypes.string,
            image: PropTypes.string,
            imageSelected: PropTypes.string,
        })
    ).isRequired,
    userCategories: PropTypes.arrayOf(PropTypes.number),
    isStandalone: PropTypes.bool,
};

PreferredCategoriesForm.defaultProps = {
    onSubmit: () => {},
    categories: [],
    userCategories: [],
    isStandalone: true,
};

const ConnectedPreferredCategoriesForm = (props) => {
    // NOTE: Default to unconnected component inside storybook environment
    // so that components that use the default export do not break.
    if (process.env.STORYBOOK_ENV === 'true') {
        return <PreferredCategoriesForm {...props} />;
    }
    const categories = useSelector(selectCategories); // eslint-disable-line
    const userCategories = useSelector(selectUserCategories); // eslint-disable-line
    const location = useContext(LocationContext); // eslint-disable-line
    const dispatch = useDispatch(); // eslint-disable-line

    const handleSubmit = (categories) => {
        // NOTE: start page is the only place we are not logged in and want
        // to set the preferred categories temporarily before registration,
        // everywhere else we are logged in and want to update it for real:
        if (matchLocation(routes.start, location)) {
            dispatch(setTempUserSettings(categories));
        } else {
            dispatch(
                updateUserSettings(categories, undefined, !props.isStandalone)
            );
        }
    };
    return (
        <PreferredCategoriesForm
            {...props}
            onSubmit={handleSubmit}
            categories={categories}
            userCategories={userCategories}
        />
    );
};

ConnectedPreferredCategoriesForm.propTypes = PreferredCategoriesForm.propTypes;
ConnectedPreferredCategoriesForm.defaultProps =
    PreferredCategoriesForm.defaultProps;

export default ConnectedPreferredCategoriesForm;
